'use-client';
import { EmailFooter, PhoneFooter } from '@/assets/icons';
import Link from 'next/link';
import React from 'react';

import { useRouter } from 'next/router';
import { useGetSetting } from '@/hooks/apis/Setting/useGetSetting';

const ContactPopup = () => {
  const router = useRouter();
  const { data: settings } = useGetSetting(router.locale || '');
  return (
    <div className="fixed bottom-5 right-5 flex flex-col gap-6 items-center">
      <Link href={`mailto:${settings?.email}`}>
        <div className="bg-blue-600 shadow-lg h-14 w-14 rounded-full hover:opacity-70 flex justify-center items-center duration-500">
          <EmailFooter />
        </div>
      </Link>
      <Link href={`tel:${settings?.phone}`}>
        <div className="bg-yellow-400 shadow-lg h-16 w-16 rounded-full animate-bounce hover:opacity-70 flex justify-center items-center duration-500">
          <PhoneFooter />
        </div>
      </Link>
      {/* <FacebookMsg /> */}
    </div>
  );
};

export default ContactPopup;
