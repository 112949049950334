import dynamic from 'next/dynamic';
import { SVGProps } from 'react';

export const LogoIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/logo.svg'),
);
export const PdfIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/pdf.svg'),
);
export const ComputerIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/computer.svg'),
);

export const UpArrow = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/up-arrow.svg'),
);

export const Phone = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/phone.svg'),
);

export const FB = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/facbook.svg'),
);
export const PhoneFooter = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/phone-footer.svg'),
);
export const EmailFooter = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/email-footer.svg'),
);
